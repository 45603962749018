import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import TemporaryContact from "../../Components/TemporaryContact/TemporaryContact";

const Contact =() =>{
    return(
        <>
            <Header/>
            <TemporaryContact />
            <Footer />
        </>
    )
}

export default Contact;