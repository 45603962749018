import "./App.css"
import Home from "../Pages/Home/Home"
import About from "../Pages/About/About"
import Projects from "../Pages/Projects/Projects";
import Contact from "../Pages/Contact/Contact";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import GlueRoutesystem from "../Pages/Projects/GlueRoutesystem/GlueRoutesystem";
import Music2Motion from "../Pages/Projects/Music2Motion/Music2Motion";
import ReactProjects from "../Pages/Projects/ReactProjects/ReactProjects";

const App = () =>{
    return(
        <div className="App">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/projects" element={<Projects/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/projects/GlueRouteSystem" element={<GlueRoutesystem/>} />
                <Route path="/projects/Music2Motion" element={<Music2Motion />} />
                <Route path="/projects/ReactProjects" element={<ReactProjects/>} />
            </Routes>
        </BrowserRouter>
        </div>
    );
}

export default App;