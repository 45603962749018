import Header from "../../Components/Header/Header.js";
import projectsData from "../../data/projectsData.js";
import ProjectsCard from "../../Components/ProjectsCard/ProjectsCard.js";
import "./Projects.scss"
import { useEffect, useState } from "react";

const Projects =() =>{
    const [currentFilter, setCurrentFilter] = useState("")
    const [filteredProjects, setFilteredProjects] = useState([])

    useEffect(() => {
        console.log(currentFilter);
        if(currentFilter === ""){
            setFilteredProjects(projectsData)
        }
        else{
            setFilteredProjects(projectsData.filter((project) => project.methods.includes(currentFilter)))
        }
    }, [currentFilter])

    return(
        <>
            <Header/>
            <main className="projectsSection">
                <h1 className="projects__h1">Projects</h1>
                <div className="projects__filterwrapper">
                    <button onClick={()=>setCurrentFilter(currentFilter == "React" ? "" : "React")} className={`projects__filterwrapper__filter ${currentFilter == "React" && "projects__filterwrapper__filter--selected"}`}>React</button>
                    <button onClick={()=>setCurrentFilter(currentFilter == "Laravel" ? "" : "Laravel")} className={`projects__filterwrapper__filter ${currentFilter == "Laravel" && "projects__filterwrapper__filter--selected"}`}>Laravel</button>
                    <button onClick={()=>setCurrentFilter(currentFilter == "Wordpress" ? "" : "Wordpress")} className={`projects__filterwrapper__filter ${currentFilter == "Wordpress" && "projects__filterwrapper__filter--selected"}`}>Worpress</button>
                </div>
                <section className="projects">
                    {
                        filteredProjects.map((project, index) => {
                            if(project.type === "projectscard"){
                                return(
                                    <ProjectsCard
                                        key={index}
                                        project={project}
                                    />
                                )
                            }
                        })
                    }
                </section>
            </main>
        </>
    )
}

export default Projects; 