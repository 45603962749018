import "./TemporaryContact.css";

const TemporaryContact = () =>{
    return(
        <section className="temporaryContact">
            <h1 className="temporaryContact__h1">Feel free to contact me anytime!</h1>
            <ul className="temporaryContact__ul">
                <li className="temporaryContact__li">
                    <i class="fa-regular fa-envelope"></i>
                    <a className="temporaryContact__li__a" href = "mailto: contact@krisockers.nl">contact@krisockers.nl</a>
                </li>
                <li className="temporaryContact__li">
                    <i class="fa-brands fa-linkedin"></i>
                    <a className="temporaryContact__li__a" href="https://www.linkedin.com/in/kris-ockers-251961233/" target="_blank" rel="noreferrer noopener">
                        LinkedIn
                    </a>
                </li>
            </ul>
        </section>
    );
}

export default TemporaryContact;