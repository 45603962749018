import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import glueBubble from "../../../Assets/Glue-info-bubble.jpg";
import glueImg from "../../../Assets/Glue-img.jpg";
import "./GlueRoutesystem.css";

const GlueRoutesystem = () =>{
    return(
        <>
            <Header />
            <main className="GlueRoutesystem">
                <section className="GlueRoutesystem__1">
                <img className="GlueRoutesystem__img" src={glueImg} alt="glue route example" />
                <div className="GlueRoutesystem__info">
                    <div className="GlueRoutesystem__mainTextWrapper">
                        <h1 className="GlueRoutesystem__h1">Glue Routesystem</h1>
                        <p className="GlueRoutesystem__p">
                            This project has been made together with
                            {" "}<a className="GlueRoutesystem__linknaarrobin" href="https://www.streatsdesign.com/index.html">Robin Schoenmaker</a>{" "}
                            from my class.
                            Almost all of the route page has been written by me except
                            for the part that puts you trhough to the right google maps link.
                            Robin also made the payment area for the route which Glue eventually chose to not use.
                        </p>
                        <h2>What is Glue</h2>
                        <p className="GlueRoutesystem__p">
                            GLUE is a three-day design-route from and for Amsterdam designers,
                            the general public, architects, brands,showrooms,
                            galleries, academies, and other colleagues. <br />
                            GLUE amsterdam connected by design connects all locations and provides a substantive and attractive program for colleagues and the public.
                        </p>
                        <h2>What did we have to do</h2>
                        <p className="GlueRoutesystem__p">
                            We had to create a route system based on the already existing map with its locations on it.
                            You had to be able to only see the included locations of the currently selected route and then be able to switch between a few routes.
                            For every route there should come a way to follow that route,
                            this could be in the browser or with a third party app such as google maps. <br />
                            The plan was also to put a paywall before the route system which was eventually not included. <br />
                            We were also asked to improve the overall performance of the website.
                        </p>
                        <h2>What did we do</h2>
                        <p className="GlueRoutesystem__p">
                            We created a Routesystem that has 5 routes with each their own locations
                            {" (those locations can be shared). "}
                            when a route is selected the map will display the locations of that route,
                            there is also supposed to be a line drawn between the locations but that doesnt always work because of plugin limitations.
                            With each selected route there will be buttons appearing,
                            there will be one for walking and one for cycling. 
                            The buttons have the right link to the route on google maps so you can easily follow the route on your phone.
                            Routes that have more than 10 locations will have double the buttons,
                            Part A and B for each form of transport.
                            This has been done because theres a limit of 10 locations using google maps. 
                        </p>
                    </div>
                    <p className="GlueRoutesystem__p">
                        {"> Worpress"} <br />
                        {"> Javascript"} <br />
                        {"> CSS"} <br />
                        
                    </p>
                    <div className="GlueRoutesystem__links">
                        <a target="_blank" rel="noreferrer noopener" href="https://glue.amsterdam/routemap/"><button className="card__button">View online</button></a>
                        <a className="card__githubLink" target="_blank" rel="noreferrer noopener" href="https://github.com/krihs0/Glue">
                            <i class="fa-brands fa-github"></i>
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                        </a>
                    </div>
                </div>
                </section>
                <section className="GlueRoutesystem__2">
                    <div className="GlueRoutesystem__extraInfo">
                        <div>
                        <h2>Other stuff I did for Glue</h2>
                        <p className="GlueRoutesystem__p">
                        Along the way we were also asked to fix some things that went wrong on the website.
                        One of these thing was the dissapeared css of the info bubbles of the locatons on the map.
                        For that I created two pieces of code which the staff of glue can use for making the info bubbles. <br />
                        These code pieces can be found on the github page of this project under the names: <br />
                        {"( HUB - Template ).html"} <br />
                        {"( Temporary - HUB - Template ).html"}
                        </p>
                        </div>
                        <a className="card__githubLink" target="_blank" rel="noreferrer noopener" href="https://github.com/krihs0/Glue">
                            <i class="fa-brands fa-github"></i>
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                        </a>
                        <img className="GlueRoutesystem__infobubble" src={glueBubble} alt="" />
                    </div>
                    
                    <div className="GlueRoutesystem__extraInfo">
                        <div>
                        <h2>Documents and Documentation</h2>
                            <p className="GlueRoutesystem__p">
                                On march 9th 2022 we had an in person briefing with David and Guus 2 of the 3 co founders of GLue <br />
                                For keeping track of all the tasks and who did or does what we used trello, linked below.
                                We also used notion to debrief after the in person briefing.
                            </p>
                        </div>
                        <div className="GlueRoutesystem__documentationLinks">
                        <a href="https://github.com/krihs0/Glue/blob/main/Briefing-GLUE-digital-map.pdf">
                            <button className="card__button">Briefing</button>
                        </a>
                        <a href="https://trello.com/b/e0zlZO0k/glue">
                            <button className="card__button">Trello</button>
                        </a>
                        <a href="https://chiseled-sodalite-2a8.notion.site/Debriefing-af379f406fa34a27b4164161bef15e4e">
                            <button className="card__button">Notion</button>
                        </a>
                        </div>
                        <a className="card__githubLink" target="_blank" rel="noreferrer noopener" href="https://github.com/krihs0/Glue">
                            <i class="fa-brands fa-github"></i>
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                        </a>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default GlueRoutesystem;