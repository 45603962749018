import Header from "../../../Components/Header/Header";
import ReactProjectCard from "../../../Components/ReactProjectCard/ReactProjectCard";
import "./ReactProjects.css";
import reactomon from "../../../Assets/reactomon.jpg";
import dashboard from "../../../Assets/dashboard.jpg";
import backlog from "../../../Assets/backlog.jpg";
import trellie from "../../../Assets/trellie.jpg";
import coffeez from "../../../Assets/coffeez.jpg";
import beroepsopdracht from "../../../Assets/beroepsopdracht.png";

const ReactProjects = () =>{
    return(
        <>
        <Header />
        <section className="reactprojects">
            <h1 className="reactprojects__h1">React Projects</h1>
            <div className="reactProjectCardWrapper">
            <ReactProjectCard
                img={reactomon} title="Reactomon"
                methods={["React", "Javascript", "CSS"]}
                online="http://32813.hosts1.ma-cloud.nl/pages/Reactomon/"
                github="https://github.com/krihs0/Reactomon"
                text="In this project I have made an app where you can catch pokem0n. You first have to make the health of the pokem0n as low as you can get it because the lower the health the bigger the chance of catching the pokem0n. I have copy pasted all the information about the pokem0n from a pokem0n api website. In the future i would like to update this app so it only needs the name of a certain pokem0n and then it automatically puts in all the correct information and stats."
            />
            <ReactProjectCard
                img={beroepsopdracht} title="Beroepsopdracht Collezione"
                methods={["React", "Javascript", "CSS"]}
                online="kris.krijgtookvorm.nl"
                github="https://github.com/krihs0/React-BO"
                text="Throughout the entire React journey at school I have learned a lot of things. This is the final project where I had to show everything I had learned. I got a theme and a style to work with and the rest was on me. To see everything i have learned in these few months this is a great summary. Since this Project my react skills have greatly developed my latest project in React is the customer portal for Webvalue."
            />
            <ReactProjectCard
                img={dashboard} title="Dashboard"
                methods={["React", "Javascript", "CSS"]}
                online="http://32813.hosts1.ma-cloud.nl/pages/React-Dashboard/"
                github="https://github.com/krihs0/React-Dashboard"
                text='In this project i created a dashboard that can pull components based on an input. Try putting in "sunflower" or "poppy" and see what happens.'
            />
            <ReactProjectCard
                img={backlog} title="Backlog"
                methods={["React", "Javascript", "CSS"]}
                online="http://32813.hosts1.ma-cloud.nl/pages/backlog/"
                github="https://github.com/krihs0/React-Backlog"
                text="In this project I made a complex search function that can search through a specified list of games using 3 inputs. While building this project i got introduced to working with React hooks."
            />
            <ReactProjectCard
                img={trellie} title="Trellie"
                methods={["React", "Javascript", "CSS"]}
                online="http://32813.hosts1.ma-cloud.nl/pages/Trellie/"
                github="https://github.com/krihs0/React-Trellie"
                text="In this project I created a small clone of Trello. While building this I learned to use React redux and also how to convert an app that wasnt using it before."
            />
            <ReactProjectCard
                img={coffeez} title="Coffeez"
                methods={["React", "Javascript", "CSS"]}
                online="http://32813.hosts1.ma-cloud.nl/pages/coffeez/"
                github="https://github.com/krihs0/coffeez"
                text="This is the first React project i ever made. I mostly just learned the basics of react and got explained why and when React is good to use."
            />
            </div>
        </section>
        </>
    )
}

export default ReactProjects;

/*
<ReactProjectCard
img={} title=""
methods={["React", "Javascript", "CSS"]}
online=""
github=""
text=""
/>
*/