import "./ReactProjectCard.css";
import placeholder from "../../Assets/placeholder.jpg";

const ReactProjectCard = (props) =>{
    let methods = props.methods.map(function(method){
        return <li key={method} className="reactProjectCard__method">{"> " + method}</li>
    })

    return(
        <article className="reactProjectCard">
            <img className="reactProjectCard__img" src={props.img || placeholder} alt="" />
            <div className="reactProjectCard__info">
                <div className="reactProjectCard__text">
                <h1 className="reactProjectCard__h1">{props.title || "React Project"}</h1>
                <p className="reactProjectCard__text">{props.text}</p>
                </div>
                <ul className="reactProjectCard__methods">
                    {methods}
                </ul>
                <div className="reactProjectCard__links">
                    <a target="_blank" rel="noreferrer noopener" href={props.online}><button className="reactProjectCard__button">View online</button></a>
                    <a className="reactProjectCard__githubLink" target="_blank" rel="noreferrer noopener" href={props.github}>
                        <i class="fa-brands fa-github"></i>
                    </a>
                </div>
            </div>
        </article>
    )
}

export default ReactProjectCard;