import "./About.scss"
import Header from "../../Components/Header/Header";

const About =() =>{
    return(
        <>
            <Header/>
            <main className="about">
                <div className="about__titleWrapper">
                    <h1 className="about__h1">About</h1>
                </div>
                <div className="aboutWrapper">
                    <div className="aboutTextWrapper">
                        <section className="aboutSection">
                            <h2 className="about__h2">Hi,</h2>
                            <p className="aboutSection__p">
                                as you might have seen on the homepage, 
                                my name is Kris Ockers.
                                I am a 19 year old developer from the Netherlands.
                                <br />
                                On primary school i was always the computer guy that everyone including the teachers went to. I took this with me, and so i ended up doing software development. 
                                <br/> 
                                Currently im doing some freelance work and im also working on my own projects.
                                <br/>
                                In my free time i like to play some games,
                                hang out with friends and I sometimes do skateboarding.
                            </p>
                        </section>
                        <section className="goalsSection">
                            {/* <h2 className="about__h2 goals__h2">Thing i want to learn / Goals</h2>
                            <div className="goalsListWrapper">
                            <h3>Want to learn/improve:</h3>
                            <ul className="goalsList">
                                <li className="goalsListItem">better PHP</li>
                                <li className="goalsListItem">SQL</li>
                                <li className="goalsListItem">Look into cybersecurity</li>
                            </ul>
                            </div> */}
                        </section>
                    </div>
                    <section className="qualitiesSection">
                        <div className="qualities">
                            <h2 className="qualities__h2"><i class="fa-solid fa-plus"></i>Qualities</h2>
                            <h2 className="quality__h2">Solution seeking</h2>
                            <h2 className="quality__h2">Creative</h2>
                            <h2 className="quality__h2">Endurance</h2>
                            <h2 className="quality__h2">Social</h2>
                            <h2 className="quality__h2">Independence</h2>
                        </div>
                        <div className="languages">
                            <h2 className="languages__title">Languages</h2>
                            <h2 className="languages__h2">English</h2>
                            <h2 className="languages__h2">Dutch</h2>
                            <h2 className="languages__h2--spanish">Spanish (learning)</h2>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}

export default About;