import { Link } from "react-router-dom";
import "./Footer.scss"

const Footer = () =>{
    const currentYear = new Date().getFullYear()

    return(
        <footer className="footer">
            <nav className="footer__nav">
                <ul className="footer__ul">
                    <Link className="footer__link" to="/"><li className="footer__li">Home</li></Link>
                    <Link className="footer__link" to="/about"><li className="footer__li">About me</li></Link>
                    <Link className="footer__link" to="/projects"><li className="footer__li">Projects</li></Link>
                    <Link className="footer__link" to="/contact"><li className="footer__li">Contact</li></Link>
                </ul>
            </nav>
            <p>Copyright &copy; {" "+currentYear+" "} Kris Ockers All Rights Reserved</p>
        </footer>
    )
}

export default Footer;