import "./Home.css"
import projectsData from "../../data/projectsData";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import ProjectsCard from "../../Components/ProjectsCard/ProjectsCard";
import { Link } from "react-router-dom";
import TemporaryContact from "../../Components/TemporaryContact/TemporaryContact";

const Home = () =>{
    const randomIndex = Math.floor(Math.random() * projectsData.length);

    return(
        <div className="mainInHomePage">
            <Header />
            <main>
            <section className="frontSection sectionInHomePage">
                <article className="article">
                    <h2 className="article__h2">Hi i am,</h2>
                    <h1 className="article__h1">Kris Ockers</h1>
                    <p className="article__p">I am a 3rd year software developer at Mediacollege Amsterdam</p>
                    <Link to="/about">
                        <button className="article__more">More about me!</button>
                    </Link>
                </article>
            </section>

            <section className="projectSection sectionInHomePage">
                <h2 className="projectsTitle">Notable Projects</h2>
                <ProjectsCard
                    project={projectsData[randomIndex]}
                />
                <Link to="/projects">
                    <button className="projectsButton">More Projects...</button>
                </Link>
            </section>
            <TemporaryContact />
            </main>
            <Footer />
        </div>
    );
}

export default Home;