import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import "./Music2Motion.css";

const Music2Motion = () => {
    return(
        <>
            <Header />
            <main className="music2Motion">
                <h1 className="music2Motion__h1">Coming Soon</h1>
            </main>
            <Footer />
        </>
    )
}

export default Music2Motion