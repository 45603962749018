import glueLogo from "../Assets/Glue-img.jpg";
import logo512 from "../Assets/logo512.png";
import screenshot1 from "../Assets/Screenshot 2023-04-13 103942.png";
// import screenshot2 from "../Assets/Screenshot 2023-04-13 111631.png";
import music2motion from "../Assets/Music2Motion-inaction.jpg";
import mymonstercollection from "../Assets/Screenshot 2023-11-09 153430.jpg";
import klentportal from "../Assets/klentportal.jpg";
import bmv from "../Assets/bmv.jpg";


const projectsData = [
    {
        type: "projectscard",
        title: "BMV Amsterdam pilot",
        image: bmv,
        description: "As final exam assignment me and 2 classmates were tasked to create a new website for the BMV department of the municipality of Amsterdam. After building the prototype and getting our degree we will continue to work on this project as freelance developers and hopefully see a live pilot very soon.",
        methods: ["React","Laravel","TailwindCSS"],
        github: false,
        site: "https://bmv.krisockers.nl/",
        hasPage: false,
    },
    {
        type: "projectscard",
        title: "MyMonsterCollection",
        image: mymonstercollection,
        description: "MyMonsterCollection.nl is a personal project of mine where people can keep track of their monster energy collection. The site also includes a database of all the monster energy drinks that are available. There is also the option to look for shops near you on a selected monster can, but this isnt always available since this is based on community input.",
        methods: ["Laravel","React","SASS"],
        site: "https://mymonstercollection.nl/",
        hasPage: false,
    },
    {
        type: "projectscard",
        title: "React projects",
        image: logo512,
        description: "In one of my classes i was working with react. Here is a collection of all the projects i have made in that time.",
        methods: ["React","Javascript","CSS"],
        github: "https://github.com/krihs0/",
        site: false,
        hasPage: true,
    },
    {
        type: "projectscard",
        title: "Customerportal Webvalue React",
        image: klentportal,
        description: "For my internship they asked me to realize a already existing design using react. The purpose of this customer portal is to give the client an easier way to see the status of their project(s). This is a prototype that i have made in react, I am currently working on the backend of this project using laravel.",
        methods: ["React","Laravel","SASS"],
        github: false,
        site: "http://klantenportaal-kris.krijgtookvorm.nl/home",
        hasPage: false,
    },
    {
        type: "projectscard",
        title: "Glue Routesystem",
        image: glueLogo,
        description: "GLUE is a three-day design-route from and for Amsterdam designers, architects and all other interested. Me and a classmate created a route system based on the already existing map with its locations. We have created 5 diffirent routes that can each be displayed on the map and have their own unique google maps links.",
        methods: ["Wordpress","Javascript","CSS"],
        github: "https://github.com/krihs0/Glue",
        site: "https://glue.amsterdam/routemap/",
        hasPage: true,
    },
    {
        type: "projectscard",
        title: "Content rows Webvalue",
        image: screenshot1,
        description: "In the cms that is used by Webvalue i have made a prototype for a live way of managing the site of the client. This is a prototype that i have made in react, to make the components in a site sortable i used the Jquery .sortable() function. This is not finished and is not (yet) used in the actual site.",
        methods: ["React","Jquery","CSS"],
        github: false,
        site: "http://content-rows-react.krijgtookvorm.nl/",
        hasPage: false,
    },
    {
        type: "projectscard",
        title: "Music2Motion",
        image: music2motion,
        description: "My dad has a Drive-in DJ side job where he needs a good working and attractive website for. The current site doesnt have that much function and also doesnt look all that good. He asked me to make that for him which i will start around December/January. ",
        methods: ["React","Javascript","CSS"],
        github: "https://github.com/krihs0/Music2Motion",
        site: "http://www.music2motion.nl/",
        hasPage: true,
    },
]

export default projectsData;