import { Link } from "react-router-dom";
import "./ProjectsCard.css";

const ProjectsCard = ({project}) => {

    let methods = project.methods.map(function(method){
        return <li key={method} className="card__method">{"> " + method}</li>
    })

    return(
        <article className="card">
            <div className="card__img--wrapper">
            <img className="card__img" src={project.image} alt="Project thumbnail"/>
            </div>
            <div className="card__infoWrapper">
                <div className="card__info">
                <h1 className="card__h1">{project.title}</h1>
                <p className="card__description">{project.description}</p>
                {project.hasPage && <Link to={"/projects/" + project.title.replace(/\s/g, '')}><button className="card__button">more info</button></Link>}
                {project.site && <a target="_blank" rel="noreferrer noopener" href={project.site}><button className="card__button">view online</button></a>}
                </div>
                <div className="card__methods">
                    <ul className="card__methodlist">
                        {methods}
                    </ul>
                    {project.github && <a target="_blank" rel="noopener noreferrer" className="card__githubLink" href={project.github}>
                        <i className="fa-brands fa-github"></i>
                    </a>}
                </div>
            </div>
        </article>
    )
}

export default ProjectsCard;